
::-webkit-scrollbar {
  width:14px;
}
::-webkit-scrollbar-thumb {
  border-radius:100px;
  background:#ABABAB;
  color:#ABABAB;
}
::-webkit-scrollbar-track {
background: #f1f1f1;
}

.email-sent-confirmation{
  margin-top:16vh;
  margin-bottom: 16vh;
  text-align: center;
  font-size: 2.4vw;
  color:#686868;
  font-family: 'Nunito Sans', sans-serif;
  width:50%;
  position: relative;
  left:25%;
}

.confirmation-tick{
  margin-bottom: 5vh;
  height:12vh;
}

  @media only screen and (max-width: 768px) {



  }
