
::-webkit-scrollbar {
  width:14px;
}
::-webkit-scrollbar-thumb {
  border-radius:100px;
  background:#ABABAB;
  color:#ABABAB;
}
::-webkit-scrollbar-track {
background: #f1f1f1;
}

  @media only screen and (min-width: 768px) {

    .partners-content-header{
      padding-top:4vh;
      padding-bottom:1vh;
      font-size: 6vh;
      color:#484848;
      font-family: 'Oswald', sans-serif;
      margin-left: 4vw;
    }

    .partners-content-text{
      padding-top:0vh;
      padding-bottom:6vh;
      font-size: 2.9vh;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      margin-left: 4vw;
      width:80%;
    }

    .content-link{
      padding-top:10vh;
      font-size: 4vh;
      color:#6100c2;
      font-family: 'Nunito', sans-serif;
    }

    .partner-content-image{
      width:16vw;
    }

    .content-subheader{
      padding-bottom:2vh;
      font-size: 1.4rem;
      color:#ff4800;
      font-family: 'Oswald', sans-serif;
    }

    .partners-content-image{
      width:100%;
      height:32vh;
      background-image: url("../../assets/high-res/Transland_049.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

  }

  @media only screen and (max-width: 768px) {

    .content-subheader{
      padding-top:4vh;
      padding-bottom:4vh;
      font-size: 6vw;
      color:#ff4800;
      font-family: 'Oswald', sans-serif;
    }

    .partner-content-image{
      width:100%;
    }

    .content-header{
      padding-top:4vh;
      padding-bottom:4vh;
      font-size: 5vh;
      color:#484848;
      font-family: 'Oswald', sans-serif;
      margin-left: 4vw;
    }

    .partners-content-text{
      padding-top:0vh;
      padding-bottom:3vh;
      font-size: 2.7vh;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      margin-left: 4vw;
      width:80%;
    }

    .content-link{
      font-size: 3vh;
      color:#6100c2;
      font-family: 'Nunito', sans-serif;
    }

    .partners-content-image{
        width:100%;
        height:16vh;
        background-image: url("../../assets/high-res/Transland_049.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

    }

  }
