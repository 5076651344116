
::-webkit-scrollbar {
  width:14px;
}
::-webkit-scrollbar-thumb {
  border-radius:100px;
  background:#ABABAB;
  color:#ABABAB;
}
::-webkit-scrollbar-track {
background: #f1f1f1;
}

  @media only screen and (min-width: 768px) {

    .iff-image{
      width:100%;
      height:28vh;
      background-image: url("../../assets/global-forwarding.png");
      background-size: cover;
      background-repeat: no-repeat;
    }

    .content-header{
      padding-top:8vh;
      padding-bottom:8vh;
      font-size: 8vh;
      color:#484848;
      font-family: 'Oswald', sans-serif;
      margin-left: 4vw;
    }

    .content-text{
      padding-top:0vh;
      padding-bottom:6vh;
      font-size: 4vh;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      margin-left: 4vw;
      width:80%;
    }

    .content-link{
      padding-top:10vh;
      font-size: 4vh;
      color:#6100c2;
      font-family: 'Nunito', sans-serif;
    }

    .link-container{
      margin-top:3vh;
      margin-bottom:3vh;
    }

  }

  @media only screen and (max-width: 768px) {

    .iff-image{
      width:100%;
      height:14vh;
      background-image: url("../../assets/global-forwarding.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
    .content-header{
      padding-top:4vh;
      padding-bottom:4vh;
      font-size: 5vh;
      color:#484848;
      font-family: 'Oswald', sans-serif;
      margin-left: 4vw;
    }

    .content-text{
      padding-top:0vh;
      padding-bottom:3vh;
      font-size: 3vh;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      margin-left: 4vw;
      width:80%;
    }

    .content-link{
      font-size: 3vh;
      color:#6100c2;
      font-family: 'Nunito', sans-serif;
    }

  }
