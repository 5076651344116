
::-webkit-scrollbar {
  width:14px;
}
::-webkit-scrollbar-thumb {
  border-radius:100px;
  background:#ABABAB;
  color:#ABABAB;
}
::-webkit-scrollbar-track {
background: #f1f1f1;
}

.booking-form-3{
  width:calc(40% - 8vw);
  padding-top: 4vh;
  padding-bottom: 5vh;
  margin-left: 4vw;
  display: inline-block;
  vertical-align: top;
}

.main-booking-form-header-3{
  text-align: left;
  font-size: 1.2vw;
  color:#590094;
  font-family: 'Nunito', sans-serif;
}

.terms-label{
  display: inline-block;
  width:50%;
}

.main-booking-form-columns{

}

.booking-3-main-booking-form-column-1{
  display: inline-block;
  vertical-align: top;
  margin-top:2vh;
  padding-right:2vw;
}

.booking-3-main-booking-form-column-2{
  display: inline-block;
  vertical-align: top;
  margin-top:2vh;
  max-width: 70%;
  padding-right: 2vw;
}

.booking-form-3-2{
  width:calc(60% - 4vw);
  padding-top: 4vh;
  padding-bottom: 5vh;
  margin-left: 4vw;
  display: inline-block;
  vertical-align: top;
}

.main-form-3-submit-button{
  padding:1.1vh;
  padding-left:0vw;
  padding-right:0vw;
  background:linear-gradient(90deg,#ff4800,#ff8400);
  margin-top:3vh;
  font-size: 1.2vw;
  color:white;
  font-family: 'Nunito', sans-serif;
  width:30%;
  text-align: center;
  border-radius: 100px;
  margin-left:1vw;
}
.main-form-3-submit-button:hover{
  cursor: pointer;
  opacity:0.6;
}

.booking-2-content{
  width:100%;
}

.booking-2-header{
  width:100%;
  text-align: center;
  font-size: 2.4vw;
  color:#590094;
  font-family: 'Nunito', sans-serif;
  padding-bottom: 8vh;
}

.calendar{
  width:90%;
  margin-top: 8vh;
  margin-bottom: 8vh;
  position: relative;
  left:5%;
  text-align: center;
}

.calendar-column{
  display: inline-block;
  width:16%;
  vertical-align: top;
  margin-left: 1vw;
  margin-right: 1vw;
}

.calendar-header{
  padding:0.6vw;
  text-align: center;
  background:#7f00ba;
  font-size: 1.4vw;
  color:white;
  font-family: 'Nunito', sans-serif;
  border-radius:10px;
  margin-bottom: 4vh;
}

.date-container-unavailable{
  width:calc(100% - 4px);
  margin-top: 2vh;
  margin-bottom: 2vh;
  position: relative;
  justify-content: center;
  text-align: center;
  padding-top: 1.2vw;
  padding-bottom: 1.2vw;
  border:2px solid #590094;
  border-radius: 16px;
  color:#787878;
}

.date-container-selected{
  width:calc(100% - 4px);
  margin-top: 2vh;
  margin-bottom: 2vh;
  position: relative;
  justify-content: center;
  text-align: center;
  padding-top: 1.2vw;
  padding-bottom: 1.2vw;
  border:2px solid #008f58;
  border-radius: 16px;
  background: #00d986;
  color:white;
}

.date-container-selected:hover{
  cursor:pointer;
}

.date-container-available{
  width:calc(100% - 4px);
  margin-top: 2vh;
  margin-bottom: 2vh;
  position: relative;
  justify-content: center;
  text-align: center;
  padding-top: 1.2vw;
  padding-bottom: 1.2vw;
  border:2px solid #590094;
  border-radius: 16px;
  color:#590094;
}

.date-container-available:hover{
  cursor:pointer;
  background: #590094;
  color:white;
}

.date-text{
  font-size: 1.2vw;
  font-family: 'Nunito', sans-serif;
  text-align: center;
}

.date-price{
  font-size: 1.4vw;
  font-weight: bold;
  margin-top: 0.5vw;
  font-family: 'Nunito', sans-serif;
  text-align: center;
}


.date-icon{
  width:3vw;
  margin-bottom: 2vw;
}

.booking-2-submit-button{
  padding:1.4vh;
  padding-left:0vw;
  padding-right:0vw;
  background:linear-gradient(90deg,#ff4800,#ff8400);
  margin-top:4vh;
  font-size: 1.4vw;
  color:white;
  font-family: 'Nunito', sans-serif;
  width:40%;
  text-align: center;
  position: relative;
  left:30%;
  border-radius: 100px;
  margin-bottom: 10vh;
}
.booking-2-submit-button:hover{
  cursor: pointer;
  opacity:0.6;
}

.booking-3-error{
  width:80%;
  margin-top: 4vh;
  color:#e30035;
  font-size: 1.4vw;
  text-align: left;
  font-family: 'Nunito', sans-serif;
}

.booking-3-login-panel{
  width:calc(80% - 4px);
  border: 2px solid #590094;
  border-radius: 12px;
  padding-top: 4vh;
  padding-bottom: 4vh;
  margin-top: 10vh;
  text-align: center;
}

.no-login-text{

}

  @media only screen and (min-width: 768px) {

    .content-image{
      width:100%;
    }



    .content-header{
      padding-top:8vh;
      padding-bottom:8vh;
      font-size: 8vh;
      color:#484848;
      font-family: 'Oswald', sans-serif;
      margin-left: 4vw;
    }

    .content-text{
      padding-top:0vh;
      padding-bottom:6vh;
      font-size: 3.2vh;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      margin-left: 4vw;
      width:80%;
    }

    .content-link{
      padding-top:10vh;
      font-size: 4vh;
      color:#6100c2;
      font-family: 'Nunito', sans-serif;
    }

  }

  @media only screen and (max-width: 768px) {

    .terms-label{
      display: inline-block;
      width:100%;
    }


    .booking-2-header{
      width:100%;
      text-align: center;
      font-size: 4vw;
      color:#590094;
      font-family: 'Nunito', sans-serif;
      padding-bottom: 8vh;
    }

    .date-icon{
      width:5vw;
      margin-bottom: 2vw;
    }

    .calendar{
      width:100%;
      margin-top: 8vh;
      margin-bottom: 8vh;
      position: relative;
      left:0%;
      text-align: center;
    }


    .date-text{
      font-size: 2vw;
      font-family: 'Nunito', sans-serif;
      text-align: center;
    }

    .date-price{
      font-size: 3vw;
      font-weight: bold;
      margin-top: 0.5vw;
      font-family: 'Nunito', sans-serif;
      text-align: center;
    }

    .calendar-header{
      display: none;
    }

    .content-image{
      width:100%;
    }
    .main-booking-form-header-3{
      text-align: center;
      font-size: 4vw;
      color:#590094;
      font-family: 'Nunito', sans-serif;
    }

    .content-header{
      padding-top:4vh;
      padding-bottom:4vh;
      font-size: 5vh;
      color:#484848;
      font-family: 'Oswald', sans-serif;
      margin-left: 4vw;
    }

    .content-text{
      padding-top:0vh;
      padding-bottom:3vh;
      font-size: 3vh;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      margin-left: 4vw;
      width:80%;
    }

    .content-link{
      font-size: 3vh;
      color:#6100c2;
      font-family: 'Nunito', sans-serif;
    }

    .booking-form-3{
      width:100%;
      text-align: center;
      margin-left: 0;
      padding-bottom: 0vh;
    }

    .booking-form-3-2{
      width:90%;
      text-align: center;
      margin-left: 0;
      position: relative;
      left:5%;
    }

    .booking-3-main-booking-form-column-1{
      width:calc(95% - 6vw);
      padding-left: 6vw;
    }

    .booking-3-main-booking-form-column-2{
      width:calc(95% - 6vw);
      padding-left: 6vw;
      max-width:100%;
    }

    .main-form-3-submit-button{
      position: relative;
      left:0%;
      font-size: 3.4vw;
      width:100%;
      margin-left:0vw;
    }



  }
