

::-webkit-scrollbar {
  width:14px;
}
::-webkit-scrollbar-thumb {
  border-radius:100px;
  background:#ABABAB;
  color:#ABABAB;
}
::-webkit-scrollbar-track {
background: #f1f1f1;
}

  .a{
    color:inherit;
    color:#560094;
  }

  .my-settings-column{
    width:60%;
    position: relative;
    left:20%;
    padding-top: 4vh;
    text-align: center;
  }

  .settings-submit-button{
    padding:1.4vh;
    padding-left:0vw;
    padding-right:0vw;
    background:linear-gradient(90deg,#ff4800,#ff8400);
    margin-top:6vh;
    font-size: 1.4vw;
    color:white;
    font-family: 'Nunito', sans-serif;
    width:50%;
    text-align: center;
    border-radius: 100px;
  }

  .settings-error{
    font-size:1.4vw;
    color:#e0003c;
    font-family: 'Nunito', sans-serif;
    margin-top: 3vh;
    text-align: left;
  }




  @media only screen and (max-width: 768px) {

    .main-form-submit-button{
      position: relative;
      left:10%;
      font-size: 3.4vw;
      width:80%;
    }

    .settings-error{
      font-size:3.2vw;
      color:#e0003c;
      font-family: 'Nunito', sans-serif;
      margin-top: 2vh;
    }



  }
