
@media only screen and (max-width: 768px) {
  .footer{
    width:100%;
    text-align: left;
    background:linear-gradient(90deg,#ff4800,#ff8400);
    padding-top:4vh;
    padding-bottom:4vh;
    position: relative;
    overflow-x: hidden;
  }

  .footer-newsletter-form{
    position: relative;
    left:14%;
    width:72%;
    text-align: center;
    z-index:2;
  }

  .footer-newsletter-header{
    padding:2vh;
    padding-left:2vw;
    padding-right:2vw;
    background:#590094;
    color:white;
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-size:3.2vw;
    border-radius:100px;
    margin-top:4vh;
  }

  .footer-newsletter-input{
    margin-top: 4vh;
    width:calc(100% - 4vw);
    padding:2vw;
    font-size:2.4vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    border: 2px solid #590094;
    border-radius:100px;
    padding-top:2vh;
    padding-bottom:2vh;
    border:0px;
    display: inline-block;
  }
  .footer-newsletter-input:focus{
    outline:none;
  }

  .footer-newsletter-button{
    position: relative;
    left:20%;
    padding:1.4vh;
    padding-left:0vw;
    padding-right:0vw;
    background:linear-gradient(90deg,#590094,#6100c2);
    margin-top:4vh;
    font-size: 3.2vw;
    color:white;
    font-family: 'Nunito', sans-serif;
    width:60%;
    text-align: center;
    border-radius: 100px;
    border:2px solid white;
  }
  .footer-newsletter-button:hover{
    cursor: pointer;
    opacity:0.7;
  }

  .footer-copyright-notice{
    margin-top:6vh;
    font-size: 3.2vw;
    color:white;
    font-family: 'Nunito', sans-serif;
  }

  .footer-image{
    position: absolute;
    bottom:0px;
    right:0px;
    width:90%;
    display:none;
  }

  .social-media-footer-icons{
    width:100%;
    text-align: center;
    z-index:3000;
  }

  .social-media-footer-icon{
    display: inline-block;
    vertical-align: middle;
    width:6vw;
    margin:10px;
    border-radius:8px;
    background: white;
    padding:1vh;
    z-index:3000;
  }



}




@media only screen and (min-width: 768px) {


    .social-media-footer-icons{
      position: absolute;
      top:2vh;
      right:2vw;
    }

    .social-media-footer-icon{
      display: inline-block;
      vertical-align: middle;
      width:3vw;
      margin:10px;
      border-radius:8px;
      background: white;
      padding:1vh;
    }

  .footer{
    width:100%;
    text-align: left;
    background:linear-gradient(90deg,#ff4800,#ff8400);
    padding-top:4vh;
    padding-bottom:4vh;
    position: relative;
    overflow-x: hidden;
  }

  .footer-newsletter-form{
    position: relative;
    left:6%;
    width:35%;
    text-align: center;
  }

  .footer-newsletter-header{
    padding:1.6vh;
    padding-left:2vw;
    padding-right:2vw;
    background:#590094;
    color:white;
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-size:1.2vw;
    border-radius:100px;
    margin-top:4vh;
  }

  .footer-newsletter-input{
    margin-top: 4vh;
    width:calc(100% - 2vw);
    padding:1vw;
    font-size:1vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    border: 2px solid #590094;
    border-radius:100px;
    padding-top:2vh;
    padding-bottom:2vh;
    border:0px;
    display: inline-block;
  }
  .footer-newsletter-input:focus{
    outline:none;
  }

  .footer-newsletter-button{
    position: relative;
    left:20%;
    padding:1.4vh;
    padding-left:0vw;
    padding-right:0vw;
    background:linear-gradient(90deg,#590094,#6100c2);
    margin-top:4vh;
    font-size: 1.2vw;
    color:white;
    font-family: 'Nunito', sans-serif;
    width:60%;
    text-align: center;
    border-radius: 100px;
  }
  .footer-newsletter-button:hover{
    cursor: pointer;
    opacity:0.7;
  }

  .footer-copyright-notice{
    margin-top:6vh;
    font-size: 1.2vw;
    color:white;
    font-family: 'Nunito', sans-serif;
  }

  .footer-image{
    position: absolute;
    bottom:0px;
    right:0px;
    width:50%;
  }

}
