

@media only screen and (max-width: 768px) {
  .custom-input{
    margin-top:2.4vh;
    text-align: left;
  }

  .settings-input-text{
    text-align: left;
    font-size:3.2vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
  }

  .settings-input-textbox{
    margin-top: 1vh;
    width:90%;
    padding:1vw;
    font-size:2.4vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 0px 2px 2px #E5E5E5;
    border-radius:100px;
    padding-top:1vh;
    padding-bottom:1vh;
    border:0px;
    display: inline-block;
  }
  .settings-input-textbox:focus{
    outline: none;
  }

}




@media only screen and (min-width: 768px) {

  .settings-input{
    margin-top:2.4vh;
    text-align: left;
  }

  .settings-input-text{
    text-align: left;
    font-size:1vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
  }

  .settings-input-textbox{
    margin-top: 1vh;
    width:90%;
    padding:1vw;
    font-size:1vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 0px 2px 2px #E5E5E5;
    border-radius:100px;
    padding-top:1vh;
    padding-bottom:1vh;
    border:0px;
    display: inline-block;
  }
  .settings-input-textbox:focus{
    outline: none;
  }



}
