
  @media only screen and (max-width: 768px) {

    .header{
      overflow-x: hidden;
      width: 100%;
    }

    a{
      text-decoration: none;
    }

    .header-1-main{
      width:100%;
      padding-top:1.5vh;
      padding-bottom:1.5vh;
      background:linear-gradient(90deg,#ff4800,#ff8400);
      text-align: right;
    }

    .header-1-button{
      padding:2.4vw;
      padding-top:1vh;
      padding-bottom:1vh;
      background:white;
      border-radius:1000px;
      vertical-align: middle;
      display: inline-block;
      font-size: 1rem;
      color:#490091;
      font-family: 'Comfortaa', cursive;
      margin-right:2vw;
      font-weight: bold;
    }
    .header-1-button:hover{
      cursor:pointer;
      color:white;
      background:#490091;
    }

    .dashboard-logout-button{
      padding:2.4vw;
      padding-top:1vh;
      padding-bottom:1vh;
      background:white;
      border-radius:1000px;
      vertical-align: middle;
      display: inline-block;
      font-size: 4vw;
      color:white;
      border:2px solid white;
      background:#e0002d;
      font-family: 'Comfortaa', cursive;
      margin-right:2vw;
      font-weight: bold;
    }
    .dashboard-logout-button:hover{
      cursor:pointer;
    }

    .header-2-main{
      width:100%;
      padding-top:2vh;
      padding-bottom:2vh;
      background:linear-gradient(90deg,#590094,#6100c2);
      text-align: left;
      position: relative;
    }

    .header-2-logo{
      vertical-align: middle;
      display: inline-block;
      height:7vh;
      position: relative;
      left:5%;
    }

    .header-2-menu{
      display: none;
    }

    .menu-icon{
      position: absolute;
      top:4vh;
      right:5%;
      height:4vh;
    }

    .side-menu{
      position: fixed;
      right:0px;
      top:0px;
      height:100%;
      background:white;
      width:0%;
      z-index:22;
      border-left:2px solid #6100c2;
      visibility: hidden;
    }

    .side-menu-x{
      position: absolute;
      top:20px;
      right:20px;
      width:4vw;
      visibility: hidden;
    }
    .side-menu-x:hover{
      cursor:pointer;
    }

    .side-menu-content{
      position: absolute;
      top:10vh;
      width:100%;
      text-align: center;
    }

    .side-menu-item{
      text-align: center;
      color:#6100c2;
      font-size:5vw;
      font-family: 'Ubuntu', sans-serif;
      margin-top:7vh;
    }

  }

  .app-store-badge{
    display: inline-block;
    margin-right: 4vh;
    vertical-align: middle;
    height:3vh;
  }



    @media only screen and (max-width: 768px) {
      .app-store-badge{
        display:none;
      }

    }


  @media only screen and (min-width: 768px) {

    .dropdown {
      position: relative;
      display: inline-block;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 210px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      padding: 12px 16px;
      z-index: 1;
      border-radius: 10px;
      margin-top: 0px;
    }

    .dropdown:hover .dropdown-content {
      display: block;
    }


    a{
      text-decoration: inherit;
    }

    .menu-icon{
      display: none;
    }

    .side-menu{
      display: none;
    }

    .header-1-main{
      width:100%;
      padding-top:1.5vh;
      padding-bottom:1.5vh;
      background:linear-gradient(90deg,#ff4800,#ff8400);
      text-align: right;
    }

    .header-1-button{
      padding:1.4vw;
      padding-top:1vh;
      padding-bottom:1vh;
      background:white;
      border-radius:1000px;
      vertical-align: middle;
      display: inline-block;
      font-size: 1rem;
      color:#490091;
      font-family: 'Comfortaa', cursive;
      margin-right:2vw;
      font-weight: bold;
    }
    .header-1-button:hover{
      cursor:pointer;
      color:white;
      background:#490091;
    }

    .header-2-main{
      width:100%;
      padding-top:2vh;
      padding-bottom:2vh;
      background:linear-gradient(90deg,#590094,#6100c2);
      text-align: left;
    }

    .header-2-logo{
      vertical-align: middle;
      display: inline-block;
      height:7vh;
      position: relative;
      left:5%;
    }

    .header-2-menu{
      vertical-align: middle;
      display: inline-block;
      position: relative;
      left:15%;
    }

    .header-2-menu-item{
      vertical-align: middle;
      display: inline-block;
      margin-left:1.4vw;
      margin-right:1.4vw;
      font-size:1.2vw;
      color:white;
      font-family: 'Nunito', sans-serif;
    }
    .header-2-menu-item:hover{
      cursor: pointer;
      opacity:0.6;
    }

    .header-2-menu-item-drop{
      vertical-align: middle;
      display: inline-block;
      margin-left:1.4vw;
      margin-right:1.4vw;
      font-size:1vw;
      color:white;
      font-family: 'Nunito', sans-serif;
    }
    .header-2-menu-item-drop:hover{
      cursor: pointer;
      opacity:0.6;
    }

    .dashboard-logout-button{
      padding:3vw;
      padding-top:1.4vh;
      padding-bottom:1.4vh;
      background:white;
      border-radius:1000px;
      vertical-align: middle;
      display: inline-block;
      font-size: 2.8vh;
      color:white;
      font-family: 'Comfortaa', cursive;
      margin-right:2vw;
      font-weight: bold;
      background:#e0002d;
    }
    .dashboard-logout-button:hover{
      cursor:pointer;
    }


  }
