
.calculator-container{
  position: relative;
  left:50%;
  transform: translate(-50%, 0%);
  padding-top: 10vh;
  padding-bottom: 10vh;
  text-align: center;
}

.calculator{
  padding:4vw;
  border-radius:16px;
  border:2px solid #560094;
  width:50%;
  position: relative;
  left:50%;
  transform: translate(-50%, 0%);
}

@media only screen and (max-width: 768px) {

  .calculator{
    padding:4vw;
    border-radius:16px;
    border:2px solid #560094;
    width:80%;
    position: relative;
    left:50%;
    transform: translate(-50%, 0%);
  }

}
