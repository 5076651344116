::-webkit-scrollbar {
  width:14px;
}
::-webkit-scrollbar-thumb {
  border-radius:100px;
  background:#ABABAB;
  color:#ABABAB;
}
::-webkit-scrollbar-track {
background: #f1f1f1;
}

  @media only screen and (min-width: 768px) {

    .contacts-content{
      padding-bottom:4vh;
    }

    .content-image{
      width:100%;
    }

    .content-header-main{
      padding-top:4vh;
      padding-bottom:4vh;
      font-size: 4vh;
      color:#484848;
      font-family: 'Oswald', sans-serif;
      margin-left: 4vw;
    }

    .contact-group{
      margin-top: 5vh;
      position: relative;
      left:15%;
      width:85%;
      overflow-x: hidden;
    }


    .contact-header-main{
      color:#ff4800;
      font-family: 'Oswald', sans-serif;
      font-size: 2vw;
    }

    .contact-table{
      width:85%;
      border:0px;
      padding-top:2vh;
      border-bottom: 1px solid #989898;
      padding-bottom: 2vh;
    }

    .contact-column-left{
      padding-top:1vh;
      padding-bottom:1vh;
      font-size: 1.3vw;
      width:50%;
      text-align:left;
      color:#787878;
      font-family: 'Nunito Sans', sans-serif;
    }

    .contact-column-right{
      padding-top:1vh;
      padding-bottom:1vh;
      width:50%;
      text-align:left;
      font-size: 1.3vw;
      color:#787878;
      font-family: 'Nunito', sans-serif;
    }

  }

  @media only screen and (max-width: 768px) {

    .contacts-content{
      padding-bottom: 2vh;
    }

    .content-image{
      width:100%;
    }

    .content-header-main{
      padding-top:4vh;
      padding-bottom:4vh;
      font-size: 5vh;
      color:#484848;
      font-family: 'Oswald', sans-serif;
      margin-left: 4vw;
    }

    .contact-group{
      position: relative;
      left:5%;
      width:90%;
      margin-top:4vh;
    }

    .contact-header-main{
      color:#ff4800;
      font-family: 'Oswald', sans-serif;
      font-size: 4.8vw;
    }

    .contact-table{
      width:85%;
      border:0px;
      padding-top:2vh;
    }

    .contact-column-left{
      padding-top:2vh;
      padding-bottom:2vh;
      font-size: 3vw;
      width:50%;
      text-align:left;
      color:#787878;
      font-family: 'Nunito Sans', sans-serif;
    }

    .contact-column-right{
      padding-top:2vh;
      padding-bottom:2vh;
      width:50%;
      text-align:right;
      font-size: 3vw;
      color:#787878;
      font-family: 'Nunito', sans-serif;
    }

  }
