
::-webkit-scrollbar {
  width:14px;
}
::-webkit-scrollbar-thumb {
  border-radius:100px;
  background:#ABABAB;
  color:#ABABAB;
}
::-webkit-scrollbar-track {
background: #f1f1f1;
}

  @media only screen and (min-width: 768px) {

    .client-tracking-content{
      position: relative;
      left:25%;
      width:50%;
      padding-top:8vh;
      padding-bottom:8vh;
    }

    .tracking-welcome-header{
      font-size: 1.2vw;
      color:#590094;
      font-family: 'Nunito Sans', sans-serif;
    }

    .tracking-form{
      width:calc(100% - 6vw);
      padding:3vw;
      padding-top:0px;
      padding-bottom: 8vh;
      border-radius:20px;
      border:2px solid #590094;
      margin-top:3vh;
      background:white;
    }

    .tracking-form-header{
      font-size: 1.8vw;
      color:#590094;
      font-family: 'Oswald', sans-serif;
      margin-top:2vh;
      margin-bottom:2vh;
    }


    .content-text{
      padding-top:0vh;
      padding-bottom:6vh;
      font-size: 4vh;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      margin-left: 4vw;
      width:80%;
    }

    .tracking-submit-button{
      padding:1.4vh;
      padding-left:0vw;
      padding-right:0vw;
      background:linear-gradient(90deg,#ff4800,#ff8400);
      margin-top:8vh;
      font-size: 1.4vw;
      color:white;
      font-family: 'Nunito', sans-serif;
      width:calc(18vw - 2.8vh);
      text-align: center;
      border-radius: 100px;
    }
    .tracking-submit-button:hover{
      cursor: pointer;
      opacity:0.6;
    }

    .tracking-form-arrow-icon{
      margin-left: 1vw;
      width:20px;
      height:20px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom:2px;
    }

    .background-content{
      position: relative;
    }

    .watermark-background{
      position: absolute;
      top:8vh;
      left:10%;
      width:40%;
    }

    .tracking-form-link{
      margin-top:2vh;
      color:#590094;
    }
    .tracking-form-link:hover{
      text-decoration: underline;
    }

    .tracking-summary{
      width:60%;
      padding:6vw;
      padding-top:4vh;
      border:3px solid #590094;
      background: #590094;
      border-radius:16px;
      margin-top:14vh;
      margin-bottom: 14vh;
      position: relative;
      left:50%;
      transform: translate(-50%, 0%);
      text-align: center;
    }

    .tracking-summary-header{
      text-align: center;
      font-size: 3vw;
      color:white;
      font-family: 'Oswald', sans-serif;
    }

    .tracking-content{
      margin-top:4vh;
      font-size: 1.6vw;
      color:white;
      text-align: left;
      font-family: 'Nunito', sans-serif;
      line-height: 200%;
    }

    .tracking-error{
      margin-top:4vh;
      font-size: 1.4vw;
      color:#f00034;
      font-family: 'Nunito', sans-serif;
    }



  }

  @media only screen and (max-width: 768px) {

    .client-tracking-content{
      position: relative;
      left:5%;
      width:90%;
      padding-top:5vh;
      padding-bottom:5vh;
    }

    .tracking-welcome-header{
      font-size: 3vw;
      color:#590094;
      font-family: 'Nunito Sans', sans-serif;
    }

    .tracking-form{
      width:calc(100% - 6vw);
      padding:3vw;
      padding-top:0px;
      padding-bottom: 8vh;
      border-radius:20px;
      border:2px solid #590094;
      margin-top:8vh;
      background:white;
    }

    .tracking-form-header{
      font-size: 4.2vw;
      color:#590094;
      font-family: 'Oswald', sans-serif;
      margin-top:2vh;
      margin-bottom:2vh;
    }


    .content-text{
      padding-top:0vh;
      padding-bottom:6vh;
      font-size: 4vh;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      margin-left: 4vw;
      width:80%;
    }

    .tracking-submit-button{
      padding:1.4vh;
      padding-left:0vw;
      padding-right:0vw;
      background:linear-gradient(90deg,#ff4800,#ff8400);
      margin-top:8vh;
      color:white;
      font-family: 'Nunito', sans-serif;
      width:50%;
      text-align: center;
      border-radius: 100px;
    }
    .tracking-submit-button:hover{
      cursor: pointer;
      opacity:0.6;
    }

    .tracking-form-arrow-icon{
      margin-left: 1vw;
      width:20px;
      height:20px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom:2px;
    }

    .background-content{
      position: relative;
    }

    .watermark-background{
      position: absolute;
      top:8vh;
      left:10%;
      width:40%;
    }

    .tracking-form-link{
      margin-top:2vh;
      color:#590094;
    }
    .tracking-form-link:hover{
      text-decoration: underline;
    }


    .tracking-summary{
      width:70%;
      padding:6vw;
      padding-top:4vh;
      border:3px solid #590094;
      background: #590094;
      border-radius:16px;
      margin-top:14vh;
      margin-bottom: 14vh;
      position: relative;
      left:50%;
      transform: translate(-50%, 0%);
      text-align: center;
    }

    .tracking-summary-header{
      text-align: center;
      font-size: 5vw;
      color:white;
      font-family: 'Oswald', sans-serif;
    }

    .tracking-content{
      margin-top:3vh;
      font-size: 3vw;
      color:white;
      text-align: left;
      font-family: 'Nunito', sans-serif;
      line-height: 200%;
    }

    .tracking-error{
      margin-top:3vh;
      font-size: 3vw;
      color:#f00034;
      font-family: 'Nunito', sans-serif;
    }


  }
