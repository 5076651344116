

@media only screen and (max-width: 768px) {
  .custom-input{
    margin-top:2.4vh;
    text-align: left;
  }

  .custom-input-text{
    text-align: left;
    font-size:3.2vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
  }

  .custom-input-textbox{
    margin-top: 1vh;
    width:85%;
    padding:1vw;
    font-size:3.2vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 0px 2px 2px #E5E5E5;
    border-radius:100px;
    padding-top:1vh;
    padding-bottom:1vh;
    border:0px;
    display: inline-block;
  }
  .custom-input-textbox:focus{
    outline: none;
  }

  .custom-input-dropdown{
    margin-top: 1vh;
    width:90%;
    padding:0.9vw;
    font-size:2vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 0px 2px 2px #E5E5E5;
    border-radius:100px;
    padding-top:1vh;
    padding-bottom:1vh;
    border:0px;
    display: inline-block;
  }
  .custom-input-dropdown:focus{
    outline: none;
  }

  .custom-input-date{
    margin-top: 1vh;
    width:90%;
    text-align: left;
    font-size:2vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 0px 2px 2px #E5E5E5;
    border-radius:100px;
    padding-top:1vh;
    padding-bottom:1vh;
    border:0px;
    display: inline-block;
  }
  .custom-input-date:focus{
    outline: none;
  }

  .react-datepicker {
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: .8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: .3rem;
    display: inline-block;
    position: relative;
}

}




@media only screen and (min-width: 768px) {

  .custom-input{
    margin-top:2.4vh;
    text-align: left;
  }

  .custom-input-text{
    text-align: left;
    font-size:0.8vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
  }

  .custom-input-textbox{
    margin-top: 0.6vh;
    width:10vw;
    padding:1vw;
    font-size:1vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 0px 2px 2px #E5E5E5;
    border-radius:100px;
    padding-top:0.6vh;
    padding-bottom:0.6vh;
    border:0px;
    display: inline-block;
  }
  .custom-input-textbox:focus{
    outline: none;
  }

  .custom-input-dropdown{
    margin-top: 0.6vh;
    width:12vw;
    padding:1vw;
    font-size:1vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 0px 2px 2px #E5E5E5;
    border-radius:100px;
    padding-top:0.6vh;
    padding-bottom:0.6vh;
    border:0px;
    display: inline-block;
  }
  .custom-input-dropdown:focus{
    outline: none;
  }

  .custom-input-date{
    margin-top: 0.6vh;
    margin-left: -0.25vw;
    padding-left: 0.5vw;
    text-align: left;
    font-size:1vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 0px 2px 2px #E5E5E5;
    border-radius:100px;
    padding-top:0.6vh;
    padding-bottom:0.6vh;
    border:0px;
    display: inline-block;
  }
  .custom-input-date:focus{
    outline: none;
  }

  .react-datepicker {
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: .7rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: .3rem;
    display: inline-block;
    position: relative;
}

}
