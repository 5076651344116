

@media only screen and (max-width: 768px) {
  .form-input{
    margin-top:2.4vh;
    text-align: left;
  }

  .form-input-text{
    text-align: left;
    font-size:2.4vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
  }

  .form-input-textbox{
    margin-top: 1vh;
    width:90%;
    padding:1vw;
    font-size:2vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 0px 2px 2px #E5E5E5;
    border-radius:100px;
    padding-top:0.7vh;
    padding-bottom:0.7vh;
    border:0px;
    display: inline-block;
  }
  .form-input-textbox:focus{
    outline: none;
  }

  .form-input-dropdown{
    margin-top: 1vh;
    width:90%;
    padding:1vw;
    font-size:2.2vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 0px 2px 2px #E5E5E5;
    border-radius:100px;
    padding-top:1vh;
    padding-bottom:1vh;
    border:0px;
    display: inline-block;
  }
  .form-input-dropdown:focus{
    outline: none;
  }

  .form-input-date{
    margin-top: 1vh;
    width:90%;
    text-align: center;
    font-size:2.2vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 0px 2px 2px #E5E5E5;
    border-radius:100px;
    padding-top:1vh;
    padding-bottom:1vh;
    border:0px;
    display: inline-block;
  }
  .form-input-date:focus{
    outline: none;
  }

  .react-datepicker {
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: .8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: .3rem;
    display: inline-block;
    position: relative;
}

  .login-form-submit-button{
    padding:1.4vh;
    padding-left:0vw;
    padding-right:0vw;
    background:linear-gradient(90deg,#ff4800,#ff8400);
    margin-top:4vh;
    font-size: 1.4vw;
    color:white;
    font-family: 'Nunito', sans-serif;
    width:100%;
    text-align: center;
    border-radius: 100px;
  }
  .login-form-submit-button:hover{
    cursor: pointer;
    opacity:0.6;
  }

  .login-form-arrow-icon{
    margin-left: 1vw;
    width:20px;
    height:20px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom:2px;
  }

}




@media only screen and (min-width: 768px) {

  .form-input{
    margin-top:3vh;
    text-align: left;
  }

  .form-input-text{
    text-align: left;
    font-size:1.2vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
  }

  .form-input-textbox{
    margin-top: 1vh;
    width:24vw;
    padding:1vw;
    font-size:1.1vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 0px 2px 2px #E5E5E5;
    border-radius:100px;
    padding-top:1vh;
    padding-bottom:1vh;
    border:0px;
    display: inline-block;
  }
  .form-input-textbox:focus{
    outline: none;
  }

  .form-input-dropdown{
    margin-top: 1vh;
    width:14vw;
    padding:1vw;
    font-size:1.1vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 0px 2px 2px #E5E5E5;
    border-radius:100px;
    padding-top:1vh;
    padding-bottom:1vh;
    border:0px;
    display: inline-block;
  }
  .form-input-dropdown:focus{
    outline: none;
  }

  .form-input-date{
    margin-top: 1vh;
    width:10vw;
    text-align: center;
    font-size:1.1vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 0px 2px 2px #E5E5E5;
    border-radius:100px;
    padding-top:1vh;
    padding-bottom:1vh;
    border:0px;
    display: inline-block;
  }
  .form-input-date:focus{
    outline: none;
  }

  .react-datepicker {
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: .8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: .3rem;
    display: inline-block;
    position: relative;
}

}
