
::-webkit-scrollbar {
  width:14px;
}
::-webkit-scrollbar-thumb {
  border-radius:100px;
  background:#ABABAB;
  color:#ABABAB;
}
::-webkit-scrollbar-track {
background: #f1f1f1;
}

  @media only screen and (min-width: 768px) {

    .client-register-content{
      position: relative;
      left:25%;
      width:50%;
      padding-top:8vh;
      padding-bottom:8vh;
    }

    .register-welcome-header{
      font-size: 1.2vw;
      color:#590094;
      font-family: 'Nunito Sans', sans-serif;
    }

    .register-info-header{
      font-size: 1.1vw;
      color:#484848;
      font-family: 'Nunito Sans', sans-serif;
      margin-top:1vw;
      margin-bottom:1vw;
    }

    .register-form{
      width:calc(100% - 6vw);
      padding:3vw;
      padding-top:0px;
      padding-bottom: 8vh;
      border-radius:20px;
      border:2px solid #590094;
      margin-top:2vh;
      background:white;
    }

    .register-form-header{
      font-size: 1.8vw;
      color:#590094;
      font-family: 'Oswald', sans-serif;
      margin-top:1vh;
      margin-bottom:1vh;
    }


    .content-text{
      padding-top:0vh;
      padding-bottom:6vh;
      font-size: 4vh;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      margin-left: 4vw;
      width:80%;
    }

    .register-submit-button{
      padding:1.4vh;
      padding-left:0vw;
      padding-right:0vw;
      background:linear-gradient(90deg,#ff4800,#ff8400);
      margin-top:8vh;
      font-size: 1.4vw;
      color:white;
      font-family: 'Nunito', sans-serif;
      width:calc(18vw - 2.8vh);
      text-align: center;
      border-radius: 100px;
    }
    .register-submit-button:hover{
      cursor: pointer;
      opacity:0.6;
    }

    .register-form-arrow-icon{
      margin-left: 1vw;
      width:20px;
      height:20px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom:2px;
    }

    .background-content{
      position: relative;
    }

    .watermark-background{
      position: absolute;
      top:8vh;
      left:10%;
      width:40%;
    }

    .register-form-link{
      margin-top:2vh;
      color:#590094;
    }
    .register-form-link:hover{
      text-decoration: underline;
    }

    .errors-container{
      color:#f50029;
      font-weight:bold;
      padding-top:5vh;
      padding-bottom:2vh;
      font-size: 2vw;
    }

    .success-form-notice{
      width:100%;
      text-align: center;
    }

    .success-title{
      font-size: 3vw;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      margin-top:2vh;
    }

    .success-subtitle{
      margin-top:2vh;
      font-size: 2vw;
      color:#787878;
      font-family: 'Nunito', sans-serif;
    }

    .success-icon{
      width:10vw;
      margin-top: 4vh;
    }



  }

  @media only screen and (max-width: 768px) {

    .success-form-notice{
      width:calc(100% - 6vw);
      padding-left:3vw;
      padding-right:3vw;
      text-align: center;
    }

    .success-title{
      font-size: 5vw;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      margin-top:1.2vh;
    }

    .success-subtitle{
      margin-top:2vh;
      font-size: 4vw;
      color:#787878;
      font-family: 'Nunito', sans-serif;
    }

    .success-icon{
      width:12vw;
      margin-top: 2.5vh;
    }

    .errors-container{
      color:#f50029;
      font-weight:bold;
      padding-top:1vh;
      padding-bottom:1vh;
      font-size: 4vw;
    }

    .success-icon{
      width:10vw;
    }

    .client-register-content{
      position: relative;
      left:5%;
      width:90%;
      padding-top:5vh;
      padding-bottom:5vh;
    }

    .register-welcome-header{
      font-size: 3vw;
      color:#590094;
      font-family: 'Nunito Sans', sans-serif;
    }

    .register-info-header{
      font-size: 2vw;
      color:#484848;
      font-family: 'Nunito Sans', sans-serif;
      margin-top:1vh;
      margin-bottom:1vh;
    }

    .register-form{
      width:calc(100% - 6vw);
      padding:3vw;
      padding-top:0px;
      padding-bottom: 8vh;
      border-radius:20px;
      border:2px solid #590094;
      margin-top:8vh;
      background:white;
    }

    .register-form-header{
      font-size: 4.2vw;
      color:#590094;
      font-family: 'Oswald', sans-serif;
      margin-top:2vh;
      margin-bottom:2vh;
    }


    .content-text{
      padding-top:0vh;
      padding-bottom:6vh;
      font-size: 4vh;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      margin-left: 4vw;
      width:80%;
    }

    .register-submit-button{
      padding:1.4vh;
      padding-left:0vw;
      padding-right:0vw;
      background:linear-gradient(90deg,#ff4800,#ff8400);
      margin-top:8vh;
      color:white;
      font-family: 'Nunito', sans-serif;
      width:50%;
      text-align: center;
      border-radius: 100px;
    }
    .register-submit-button:hover{
      cursor: pointer;
      opacity:0.6;
    }

    .register-form-arrow-icon{
      margin-left: 1vw;
      width:20px;
      height:20px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom:2px;
    }

    .background-content{
      position: relative;
    }

    .watermark-background{
      position: absolute;
      top:8vh;
      left:10%;
      width:40%;
    }

    .register-form-link{
      margin-top:2vh;
      color:#590094;
    }
    .register-form-link:hover{
      text-decoration: underline;
    }

  }
