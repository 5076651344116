
::-webkit-scrollbar {
  width:14px;
}
::-webkit-scrollbar-thumb {
  border-radius:100px;
  background:#ABABAB;
  color:#ABABAB;
}
::-webkit-scrollbar-track {
background: #f1f1f1;
}

  @media only screen and (min-width: 768px) {

    .content-image{
      width:100%;
    }

    .content-header{
      padding-top:8vh;
      padding-bottom:8vh;
      font-size: 8vh;
      color:#484848;
      font-family: 'Oswald', sans-serif;
      margin-left: 4vw;
    }

    .content-text{
      padding-top:0vh;
      padding-bottom:6vh;
      font-size: 3.2vh;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      margin-left: 4vw;
      width:80%;
    }

    .content-link{
      padding-top:10vh;
      font-size: 4vh;
      color:#6100c2;
      font-family: 'Nunito', sans-serif;
    }

    .customer{
      position: relative;
      left:8%;
      width:84%;
      padding-top:2vh;
      padding-bottom:6vh;
    }

    .customer-image{
      margin-left:3vw;
      margin-right:3vw;
      width:10vw;
      vertical-align: middle;
      display: inline-block;
      border-radius:5px;
    }

    .customer-text{
      vertical-align: middle;
      display: inline-block;
      width:calc(94% - 16vw);
    }

    .customer-header{
      color:#ff4800;
      font-family: 'Oswald', sans-serif;
      font-size: 1.8vw;
    }

    .customer-quote{
      padding-top:2vh;
      font-size: 1.1vw;
      color:#787878;
      font-family: 'Nunito', sans-serif;
    }

    .customer-name{
      font-size: 1.3vw;
      color:#787878;
      font-family: 'Nunito Sans', sans-serif;
    }

  }

  @media only screen and (max-width: 768px) {

    .content-image{
      width:100%;
    }

    .content-header{
      padding-top:4vh;
      padding-bottom:4vh;
      font-size: 5vh;
      color:#484848;
      font-family: 'Oswald', sans-serif;
      margin-left: 4vw;
    }

    .content-text{
      padding-top:0vh;
      padding-bottom:3vh;
      font-size: 3vh;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      margin-left: 4vw;
      width:80%;
    }

    .content-link{
      font-size: 3vh;
      color:#6100c2;
      font-family: 'Nunito', sans-serif;
    }

    .customer{
      position: relative;
      left:8%;
      width:84%;
      padding-top:2vh;
      padding-bottom:6vh;
    }

    .customer-image{
      margin-left:3vw;
      margin-right:3vw;
      width:22vw;
      vertical-align: middle;
      display: inline-block;
      border-radius:5px;
    }

    .customer-text{
      vertical-align: middle;
      display: inline-block;
      width:95%;
      font-size: 1.2rem;
    }

    .customer-header{
      color:#ff4800;
      font-family: 'Oswald', sans-serif;
      font-size: 1.4rem;
    }

    .customer-quote{
      padding-top:2vh;
      font-size: 3.2vw;
      color:#787878;
      font-family: 'Nunito', sans-serif;
    }

    .customer-name{
      font-size: 3.2vw;
      color:#787878;
      font-family: 'Nunito Sans', sans-serif;
    }

  }
