
::-webkit-scrollbar {
  width:14px;
}
::-webkit-scrollbar-thumb {
  border-radius:100px;
  background:#ABABAB;
  color:#ABABAB;
}
::-webkit-scrollbar-track {
background: #f1f1f1;
}

.payment-intro{
  margin-left: 8vw;
  margin-right: 8vw;
  font-size: 1.1rem;
  color:#383838;
  margin-top:3vh;
}

.booking-form-4{
  width:calc(50% - 8vw);
  padding-top: 0vh;
  padding-bottom: 10vh;
  margin-left: 8vw;
  display: inline-block;
  vertical-align: top;
}

.main-booking-form-header-4{
  text-align: left;
  font-size: 1.8vw;
  color:#590094;
  font-family: 'Nunito', sans-serif;
}

.main-booking-form-columns{

}

.main-booking-form-column-1{
  display: inline-block;
  vertical-align: top;
  margin-top:0vh;
}

.main-booking-form-column-2{
  display: inline-block;
  vertical-align: top;
  margin-top:0vh;
  padding-left:3vw;
}

.booking-form-2{
  width:calc(50% - 8vw);
  padding-top: 8vh;
  padding-bottom: 10vh;
  margin-left: 8vw;
  display: inline-block;
  vertical-align: top;
}

.main-form-submit-button{
  padding:1.4vh;
  padding-left:0vw;
  padding-right:0vw;
  background:linear-gradient(90deg,#ff4800,#ff8400);
  margin-top:6vh;
  font-size: 1.4vw;
  color:white;
  font-family: 'Nunito', sans-serif;
  width:50%;
  text-align: center;
  border-radius: 100px;
}
.main-form-submit-button:hover{
  cursor: pointer;
  opacity:0.6;
}

.booking-2-content{
  width:100%;
}

.booking-2-header{
  width:100%;
  text-align: center;
  font-size: 2.4vw;
  color:#590094;
  font-family: 'Nunito', sans-serif;
  padding-bottom: 8vh;
}

.calendar{
  width:90%;
  margin-top: 8vh;
  margin-bottom: 8vh;
  position: relative;
  left:5%;
  text-align: center;
}

.calendar-column{
  display: inline-block;
  width:16%;
  vertical-align: top;
  margin-left: 1vw;
  margin-right: 1vw;
}

.calendar-header{
  padding:0.6vw;
  text-align: center;
  background:#7f00ba;
  font-size: 1.4vw;
  color:white;
  font-family: 'Nunito', sans-serif;
  border-radius:10px;
  margin-bottom: 4vh;
}

.date-container-unavailable{
  width:calc(100% - 4px);
  margin-top: 2vh;
  margin-bottom: 2vh;
  position: relative;
  justify-content: center;
  text-align: center;
  padding-top: 1.2vw;
  padding-bottom: 1.2vw;
  border:2px solid #590094;
  border-radius: 16px;
  color:#787878;
}

.date-container-selected{
  width:calc(100% - 4px);
  margin-top: 2vh;
  margin-bottom: 2vh;
  position: relative;
  justify-content: center;
  text-align: center;
  padding-top: 1.2vw;
  padding-bottom: 1.2vw;
  border:2px solid #008f58;
  border-radius: 16px;
  background: #00d986;
  color:white;
}

.date-container-selected:hover{
  cursor:pointer;
}

.date-container-available{
  width:calc(100% - 4px);
  margin-top: 2vh;
  margin-bottom: 2vh;
  position: relative;
  justify-content: center;
  text-align: center;
  padding-top: 1.2vw;
  padding-bottom: 1.2vw;
  border:2px solid #590094;
  border-radius: 16px;
  color:#590094;
}

.date-container-available:hover{
  cursor:pointer;
  background: #590094;
  color:white;
}

.date-text{
  font-size: 1.2vw;
  font-family: 'Nunito', sans-serif;
  text-align: center;
}

.date-price{
  font-size: 1.4vw;
  font-weight: bold;
  margin-top: 0.5vw;
  font-family: 'Nunito', sans-serif;
  text-align: center;
}


.date-icon{
  width:3vw;
  margin-bottom: 2vw;
}

.booking-2-submit-button{
  padding:1.4vh;
  padding-left:0vw;
  padding-right:0vw;
  background:linear-gradient(90deg,#ff4800,#ff8400);
  margin-top:4vh;
  font-size: 1.4vw;
  color:white;
  font-family: 'Nunito', sans-serif;
  width:40%;
  text-align: center;
  position: relative;
  left:30%;
  border-radius: 100px;
  margin-bottom: 10vh;
}
.booking-2-submit-button:hover{
  cursor: pointer;
  opacity:0.6;
}

.booking-4-error{
  color:#e30035;
  font-size: 1.4vw;
  text-align: center;
  font-family: 'Nunito', sans-serif;
}

.payment-submit-button{
  background: linear-gradient(0deg, #590094,#6100c2);
  padding:1vh;
  margin-top:4vh;
  border-radius: 12px;
  font-size: 1.2vw;
  color:white;
  font-family: 'Nunito', sans-serif;
  text-align: center;
  width:50%;
}
.payment-submit-button:hover{
  opacity:0.6;
  cursor:pointer;
}

.price-display{
  font-size: 1.2vw;
  color:#590094;
  font-family: 'Nunito', sans-serif;
}

.price-table{
  width:28vw;
}

.price-display-amount{
  display: inline;
}

.summary-text{
  font-size: 1vw;
  color:#686868;
  line-height: 120%;
  width:14vw;
  word-wrap: break-word;
}

.summary-table-structure{
  font-size: 1vw;
  color:#686868;
  line-height: 120%;
  width:32vw;
  word-wrap: break-word;
}

.summary-header{
  text-align: left;
  font-size: 1.8vw;
  color:#590094;
  font-family: 'Nunito', sans-serif;
}

  @media only screen and (min-width: 768px) {

    .content-image{
      width:100%;
    }



    .content-header{
      padding-top:8vh;
      padding-bottom:8vh;
      font-size: 8vh;
      color:#484848;
      font-family: 'Oswald', sans-serif;
      margin-left: 4vw;
    }

    .content-text{
      padding-top:0vh;
      padding-bottom:6vh;
      font-size: 3.2vh;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      margin-left: 4vw;
      width:80%;
    }

    .content-link{
      padding-top:10vh;
      font-size: 4vh;
      color:#6100c2;
      font-family: 'Nunito', sans-serif;
    }

  }

  @media only screen and (max-width: 768px) {

    .summary-table-structure{
      font-size: 3vw;
      color:#686868;
      line-height: 120%;
      width:100%;
      word-wrap: break-word;
    }

    .summary-header{
      display: none;
    }

    .summary-text{
      font-size: 1.4vw;
      display: none;
    }

    .payment-submit-button{
      font-size: 3vw;
      position: relative;
      left:50%;
      transform: translate(-50%, 0%);
      margin-bottom: 3vh;
    }

    .booking-2-header{
      width:100%;
      text-align: center;
      font-size: 4vw;
      color:#590094;
      font-family: 'Nunito', sans-serif;
      padding-bottom: 8vh;
    }

    .date-icon{
      width:5vw;
      margin-bottom: 2vw;
    }

    .calendar{
      width:100%;
      margin-top: 8vh;
      margin-bottom: 8vh;
      position: relative;
      left:0%;
      text-align: center;
    }


    .date-text{
      font-size: 2vw;
      font-family: 'Nunito', sans-serif;
      text-align: center;
    }

    .date-price{
      font-size: 3vw;
      font-weight: bold;
      margin-top: 0.5vw;
      font-family: 'Nunito', sans-serif;
      text-align: center;
    }

    .calendar-header{
      display: none;
    }

    .content-image{
      width:100%;
    }
    .main-booking-form-header-4{
      text-align: center;
      font-size: 5vw;
      color:#590094;
      font-family: 'Nunito', sans-serif;
    }

    .content-header{
      padding-top:4vh;
      padding-bottom:4vh;
      font-size: 5vh;
      color:#484848;
      font-family: 'Oswald', sans-serif;
      margin-left: 4vw;
    }

    .content-text{
      padding-top:0vh;
      padding-bottom:3vh;
      font-size: 3vh;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      margin-left: 4vw;
      width:80%;
    }

    .content-link{
      font-size: 3vh;
      color:#6100c2;
      font-family: 'Nunito', sans-serif;
    }

    .booking-form-4{
      width:100%;
      text-align: center;
      margin-left: 0;
      padding-bottom: 0vh;
    }

    .booking-form-2{
      width:90%;
      text-align: center;
      margin-left: 0;
      position: relative;
      left:5%;
    }

    .main-booking-form-column-1{
      width:45%;
    }

    .main-booking-form-column-2{
      width:45%;
    }

    .main-form-submit-button{
      position: relative;
      left:10%;
      font-size: 3.4vw;
      width:80%;
    }

    .price-display{
      font-size: 3.8vw;
      text-align: center;
      width:100%;
      margin-bottom:2vh;
    }

    .price-table{
      width:50vw;
    }

    .price-display-amount{
    }

    .booking-4-error{
      color:#e30035;
      font-size: 2.8vw;
      text-align: center;
      font-family: 'Nunito', sans-serif;
      margin-top: 4vh;
    }








  }
