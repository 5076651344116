
::-webkit-scrollbar {
  width:14px;
}
::-webkit-scrollbar-thumb {
  border-radius:100px;
  background:#ABABAB;
  color:#ABABAB;
}
::-webkit-scrollbar-track {
background: #f1f1f1;
}



  @media only screen and (min-width: 768px) {

    .success-view{
      padding-top: 10vh;
      padding-bottom: 14vh;
      width:50%;
      text-align: center;
      position: relative;
      left:25%;
    }

    .success-text{
      font-family: 'Nunito', sans-serif;
      text-align: center;
      color:#686868;
      font-size: 2vw;
      margin-top: 4vh;
    }

  }

  @media only screen and (max-width: 768px) {


    .success-view{
      padding-top: 8vh;
      padding-bottom: 8vh;
      width:90%;
      text-align: center;
      position: relative;
      left:5%;
    }

    .success-text{
      font-family: 'Nunito', sans-serif;
      text-align: center;
      color:#686868;
      font-size: 4vw;
      margin-top: 4vh;
    }


  }
