

@media only screen and (max-width: 768px) {
  .rate-input{
    margin-top:2.4vh;
    text-align: left;
  }

  .rate-input-text{
    text-align: left;
    font-size:2.2vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
  }

  .rate-input-textbox{
    margin-top: 1vh;
    width:85%;
    padding:1vw;
    font-size:2vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 0px 2px 2px #E5E5E5;
    border-radius:100px;
    padding-top:1vh;
    padding-bottom:1vh;
    border:0px;
    display: inline-block;
  }
  .rate-input-textbox:focus{
    outline: none;
  }

  .rate-input-textarea{
    margin-top: 1vh;
    min-width:85%;
    max-width:85%;
    min-height:10vh;
    max-height:10vh;
    padding:1vw;
    font-size:2vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 0px 2px 2px #E5E5E5;
    border-radius:20px;
    padding-top:1vh;
    padding-bottom:1vh;
    border:0px;
    display: inline-block;
  }
  .rate-input-textarea:focus{
    outline: none;
  }

  .rate-input-dropdown{
    margin-top: 1vh;
    width:90%;
    padding:0.9vw;
    font-size:2vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 0px 2px 2px #E5E5E5;
    border-radius:100px;
    padding-top:1vh;
    padding-bottom:1vh;
    border:0px;
    display: inline-block;
  }
  .rate-input-dropdown:focus{
    outline: none;
  }

  .rate-input-date{
    margin-top: 1vh;
    width:90%;
    text-align: left;
    font-size:2vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 0px 2px 2px #E5E5E5;
    border-radius:100px;
    padding-top:1vh;
    padding-bottom:1vh;
    border:0px;
    display: inline-block;
  }
  .rate-input-date:focus{
    outline: none;
  }

  .react-datepicker {
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: .8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: .3rem;
    display: inline-block;
    position: relative;
}

}




@media only screen and (min-width: 768px) {

  .rate-input{
    margin-top:2.4vh;
    text-align: left;
    display: inline-block;
    vertical-align: middle;
    margin:10px;
  }

  .rate-input-text{
    text-align: left;
    font-size:0.8vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
  }

  .rate-input-textbox{
    margin-top: 0.6vh;
    width:20vw;
    padding:1vw;
    font-size:1vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 0px 2px 2px #E5E5E5;
    border-radius:100px;
    padding-top:0.6vh;
    padding-bottom:0.6vh;
    border:0px;
    display: inline-block;
  }
  .rate-input-textbox:focus{
    outline: none;
  }

  .rate-input-textarea{
    margin-top: 0.6vh;
    min-width:20vw;
    max-width:20vw;
    min-height:20vw;
    max-height:20vw;
    padding:1vw;
    font-size:1vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 0px 2px 2px #E5E5E5;
    border-radius:20px;
    padding-top:0.6vh;
    padding-bottom:0.6vh;
    border:0px;
    display: inline-block;
  }
  .rate-input-textarea:focus{
    outline: none;
  }

  .rate-input-dropdown{
    margin-top: 0.6vh;
    width:12vw;
    padding:1vw;
    font-size:1vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 0px 2px 2px #E5E5E5;
    border-radius:100px;
    padding-top:0.6vh;
    padding-bottom:0.6vh;
    border:0px;
    display: inline-block;
  }
  .rate-input-dropdown:focus{
    outline: none;
  }

  .rate-input-date{
    margin-top: 0.6vh;
    margin-left: -0.25vw;
    padding-left: 0.5vw;
    text-align: left;
    font-size:1vw;
    color:#787878;
    font-family: 'Nunito', sans-serif;
    box-shadow: 0px 0px 2px 2px #E5E5E5;
    border-radius:100px;
    padding-top:0.6vh;
    padding-bottom:0.6vh;
    border:0px;
    display: inline-block;
  }
  .rate-input-date:focus{
    outline: none;
  }

  .react-datepicker {
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: .7rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: .3rem;
    display: inline-block;
    position: relative;
}

}
