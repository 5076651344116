

::-webkit-scrollbar {
  width:14px;
}
::-webkit-scrollbar-thumb {
  border-radius:100px;
  background:#ABABAB;
  color:#ABABAB;
}
::-webkit-scrollbar-track {
background: #f1f1f1;
}

  .a{
    color:inherit;
    color:#560094;
  }

  .dashboard-content-container{
    width:80%;
    position: relative;
    left:10%;
    text-align: center;
  }

  .dashboard-navigation-row{
    width:100%;
    text-align: left;
    padding-top:4vh;
    padding-bottom:4vh;
    text-align: center;
  }

  .navigation-tile{
    width:14vw;
    padding:2vh;
    padding-left: 3vw;
    padding-right: 3vw;
    margin: 4vh;
    box-shadow:0px 0px 4px 2px #F0F0F2;
    border-radius: 16px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    background: #560094;
    color:white;
  }
  .navigation-tile:hover{
    cursor: pointer;
    color:#560094;
    background: white;
  }

  .navigation-tile-icon{
    width:5vh;
    display: inline-block;
    vertical-align: middle;
  }

  .navigation-tile-text{
    margin-top: 2vh;
    font-family: 'Nunito', sans-serif;
    font-size: 1.4vw;
  }

  .dashboard-order-history{
    width:100%;
    border-radius: 16px;
    box-shadow:0px 0px 4px 2px #F0F0F2;
    margin-bottom: 8vh;
    height:80vh;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .dashboard-order-header{
    padding:4vh;
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-size: 1.8vw;
    color:white;
    background:#560094;
  }

  .my-orders{
    width:100%;
  }

  .order-row{
    border-bottom: 1px solid #989898;
    padding: 4vh;
  }

  .order-data-left{
    width:50%;
    text-align: left;
    display: inline-block;
    vertical-align: middle;
  }

  .order-data-right{
    width:50%;
    text-align: right;
    display: inline-block;
    vertical-align: middle;
  }

  .order-data-to{
    font-family: 'Nunito', sans-serif;
    font-size: 1.2vw;
    color:#560094;
    display: inline-block;
    vertical-align: middle;
  }

  .order-data-date{
    font-family: 'Nunito', sans-serif;
    font-size: 1.2vw;
    color:#989898;
    display: inline-block;
    vertical-align: middle;
  }

  .order-data-tracking-button{
    font-family: 'Nunito', sans-serif;
    font-size: 1.2vw;
    color:white;
    background:linear-gradient(90deg,#ff4800,#ff8400);
    padding:3vw;
    padding-top:1vh;
    padding-bottom:1vh;
    border-radius:100px;
    text-align: center;
  }
  .order-data-tracking-button:hover{
    cursor:pointer;
    opacity:0.6;
  }


  @media only screen and (max-width: 768px) {


    .dashboard-content-container{
      width:90%;
      position: relative;
      left:5%;
      text-align: center;
    }

    .order-data-tracking-button{
      font-size: 2.6vw;
      padding-left: 3vw;
      padding-right: 3vw;
    }

    .dashboard-order-header{
      font-size:3.4vw;
    }

    .navigation-tile-text{
      font-size: 3vw;
      display: block;
    }

    .navigation-tile{
      width:20vw;
      margin:1vw;
    }

    .order-data-date{
      font-size:2.6vw;
    }

    .order-data-to{
      font-size:2.6vw;
    }





  }
