
::-webkit-scrollbar {
  width:14px;
}
::-webkit-scrollbar-thumb {
  border-radius:100px;
  background:#ABABAB;
  color:#ABABAB;
}
::-webkit-scrollbar-track {
background: #f1f1f1;
}

  @media only screen and (min-width: 768px) {

    .contact-us-image{
      width:100%;
      height:28vh;
      background-image: url("../../assets/high-res/Transland_058.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }


    .content-image{
      width:100%;
    }

    .contact-us-content-header{
      padding-top:2vh;
      padding-bottom:2vh;
      font-size: 6vh;
      margin-left: 6vw;
      color:#484848;
      font-family: 'Oswald', sans-serif;
    }

    .contact-us-content-text{
      padding-top:0vh;
      padding-bottom:6vh;
      font-size: 1.8vh;
      color:#484848;
      font-family: 'Nunito', sans-serif;
      width:100%;
      text-align: left;
      overflow-x: hidden;
    }

    .content-link{
      padding-top:10vh;
      font-size: 3.4vh;
      color:#6100c2;
      font-family: 'Nunito', sans-serif;
    }

    .link-container{
      margin-top:3vh;
      margin-bottom:3vh;
    }

    .content-column{
      width:30%;
      margin-left:5vw;
      margin-right:5vw;
      display: inline-block;
      vertical-align: top;
      border-radius:16px;
      padding:2vw;
      position: relative;
    }

    .map-view{
      margin-top:4vh;
      border-radius:12px;
      width:100%;
      height:20vw;
    }

    .contact-icon{
      width:7vw;
      position: absolute;
      right:20px;
    }

  }

  @media only screen and (max-width: 768px) {

    .contact-us-image{
      width:100%;
      height:28vh;
      background-image: url("../../assets/high-res/Transland_058.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .content-image{
      width:100%;
    }

    .content-header{
      padding-top:4vh;
      padding-bottom:4vh;
      font-size: 5vh;
      color:#484848;
      font-family: 'Oswald', sans-serif;
      margin-left: 4vw;
    }

    .contact-us-content-text{
      padding-top:0vh;
      padding-bottom:6vh;
      font-size: 4vh;
      color:#484848;
      font-family: 'Nunito', sans-serif;
      width:100%;
      text-align: center;
      overflow-x: hidden;
    }

    .content-link{
      padding-top:10vh;
      font-size: 4vh;
      color:#6100c2;
      font-family: 'Nunito', sans-serif;
    }

    .link-container{
      margin-top:3vh;
      margin-bottom:3vh;
    }

    .content-column{
      width:80%;
      margin-left:8vw;
      margin-right:8vw;
      display: inline-block;
      vertical-align: top;
      text-align: left;
      margin-top:6vh;
    }

    .map-view{
      margin-top:4vh;
      border-radius:12px;
      width:100%;
      height:20vw;
    }

    .contact-icon{
      margin-bottom:3vh;
      width:4vw;
    }

  }
