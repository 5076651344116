
::-webkit-scrollbar {
  width:14px;
}
::-webkit-scrollbar-thumb {
  border-radius:100px;
  background:#ABABAB;
  color:#ABABAB;
}
::-webkit-scrollbar-track {
background: #f1f1f1;
}

.postcode-warning{
  color:#d90b34;
  font-size: 1rem;
}

.booking-1-booking-form-1{
  width:calc(35% - 4vw);
  padding-top: 8vh;
  padding-bottom: 0vh;
  margin-left: 4vw;
  display: inline-block;
  vertical-align: top;
}

.booking-form-1-header{
  text-align: left;
  font-size: 1.2rem;
  color:#590094;
  font-family: 'Nunito', sans-serif;
}

.main-booking-form-columns{

}

.booking-1-main-booking-form-column-1{
  display: inline-block;
  vertical-align: top;
  margin-top:2vh;
}

.booking-1-main-booking-form-column-2{
  display: inline-block;
  vertical-align: top;
  margin-top:2vh;
  padding-left:3vw;
}

.booking-1-booking-form-2{
  width:calc(40% - 8vw);
  padding-top: 8vh;
  padding-bottom: 0vh;
  margin-left: 4vw;
  display: inline-block;
  vertical-align: top;
}

.main-booking-1-form-submit-button{
  padding:1vh;
  padding-left:0vw;
  padding-right:0vw;
  background:linear-gradient(90deg,#ff4800,#ff8400);
  margin-top:4vh;
  font-size: 1.2vw;
  color:white;
  font-family: 'Nunito', sans-serif;
  width:50%;
  text-align: center;
  border-radius: 100px;
}
.main-booking-1-form-submit-button:hover{
  cursor: pointer;
  opacity:0.6;
}

.booking-1-error{
  margin-top:4vh;
  font-size: 1.4vw;
  color:#f00034;
  font-family: 'Nunito', sans-serif;
}

.add-ons-header{
  margin-top: 3vh;
  font-size: 1.2vw;
  color:#787878;
  font-family: 'Nunito', sans-serif;
}

.add-on-checkbox{
  margin-top:1.5vh;
  color:#686868;
}


.calculator-box-container{
  position: fixed;
  top:0px;
  left:0px;
  width:100%;
  height:100%;
  z-index:100;
  background: #202020BA;
}

.calculator-box-header{
  font-size: 1.4rem;
  margin-bottom: 0vh;
  color:#590094;
  font-family: 'Nunito', sans-serif;
}

.file-container{
  display: inline-block;
  vertical-align: middle;
  margin-right: 3vw;
}

.close-calculator-button{
  position: absolute;
  top:20px;
  left:20px;
  width:30px;
}

.calculator-box{
  display: inline-block;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  width:80%;
  height:80%;
  padding:3vh;
  background: white;
  border-radius: 16px;
  overflow-y: auto;
  text-align: left;
  font-size: 1.2rem;
  color:#484848;
  padding:4vw;
}

.box-list{
  color:#590094;
  font-size: 1.1rem;
  line-height: 150%;
}

.template-link:{
  color:#590094;
  line-height: 250%;
}

.calculator-box-header{
  font-size: 1.4rem;
  margin-bottom: 0vh;
  color:#590094;
  font-family: 'Nunito', sans-serif;
}

.calculator-column-1{
  width:50%;
  display: inline-block;
  vertical-align:top;
}

.calculator-column-2{
  width:50%;
  display: inline-block;
  vertical-align:top;
}

.calculator-divider{
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  background:#787878;
  height:90%;
  width:2px;
}

.calculator-notice{
  font-family: 'Nunito', sans-serif;
  color:#787878;
  font-size: 1.4vw;
  padding:2vw;
}

.calculator-result{
  color:#590094;
  font-size: 3.4vh;
  margin-top: 3vh;
  font-family: 'Nunito', sans-serif;
}

.calculator-open-button{
  color:white;
  font-size: 2.4vh;
  align-self: center;
  font-family: 'Nunito', sans-serif;
  padding:2vw;
  padding-top:1.4vh;
  padding-bottom:1.4vh;
  display: inline-block;
  margin-top: 2.5vh;
  border-radius: 100px;
  background:linear-gradient(90deg,#590094,#6100c2);
}
.calculator-open-button:hover{
  cursor:pointer;
  opacity:0.6;
}

.uk-form-error{
  color:#cf0030;
  font-family: 'Nunito', sans-serif;
  font-size: 1.2rem;
  padding-left: 4vw;
  padding-right: 4vw;
  margin-top: 3vh;
}

.uk-form-submit-button{
  color:white;
  font-size: 2.4vh;
  align-self: center;
  font-family: 'Nunito', sans-serif;
  padding:6vw;
  padding-top:1.4vh;
  padding-bottom:1.4vh;
  display: inline-block;
  margin-top: 2.5vh;
  border-radius: 100px;
  background:linear-gradient(90deg,#590094,#6100c2);
}
.uk-form-submit-button:hover{
  cursor:pointer;
  opacity:0.6;
}

.calculator-result-image{
  width:70%;
  margin-top: 3vh;
}

.calculator-inputs-container{
  position: relative;
  left:50%;
  transform: translate(-20%, 0%);
}

  @media only screen and (min-width: 768px) {

    .content-image{
      width:100%;
    }



    .content-header{
      padding-top:8vh;
      padding-bottom:8vh;
      font-size: 4vh;
      color:#484848;
      font-family: 'Oswald', sans-serif;
      margin-left: 4vw;
    }

    .content-text{
      padding-top:0vh;
      padding-bottom:6vh;
      font-size: 3.2vh;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      margin-left: 4vw;
      width:80%;
    }

    .content-link{
      padding-top:10vh;
      font-size: 4vh;
      color:#6100c2;
      font-family: 'Nunito', sans-serif;
    }

    .pallet-images-header{
      color:#787878;
      font-family: 'Nunito', sans-serif;
      font-size: 1rem;
      margin-left: 4vw;
    }
    .pallet-images-container{
      padding-bottom: 8vh;
      margin-left: 4vw;
      margin-top: 2vh;
    }

    .pallet-image-box{
      width:40%;
      display: inline-block;
      border-radius:10px;
      position: relative;
      margin-left: 1vw;
      margin-bottom: 1vh;
    }

    .pallet-image-filter{

    }

    .pallet-image{
      width:100%;
      display: inline-block;
      border-radius:10px;
    }

    .pallets-information-container{
      display: inline-block;
      width:24%;
      padding-top: 8vh;
    }

  }

  @media only screen and (max-width: 768px) {

    .booking-1-booking-form-1{
      width:calc(90% - 4vw);
      padding-top: 8vh;
      padding-bottom: 0vh;
      margin-left: 4vw;
      display: inline-block;
      vertical-align: top;
    }

    .booking-1-booking-form-2{
      width:calc(90% - 4vw);
      padding-top: 8vh;
      padding-bottom: 0vh;
      margin-left: 4vw;
      display: inline-block;
      vertical-align: top;
    }

    .content-image{
      width:100%;
    }
    .main-booking-form-header{
      text-align: center;
      font-size: 5vw;
      color:#590094;
      font-family: 'Nunito', sans-serif;
    }

    .booking-form-1-header{
      text-align: left;
      font-size: 1.6rem;
      color:#590094;
      font-family: 'Nunito', sans-serif;
    }


    .content-header{
      padding-top:4vh;
      padding-bottom:4vh;
      font-size: 5vh;
      color:#484848;
      font-family: 'Oswald', sans-serif;
      margin-left: 4vw;
    }

    .content-text{
      padding-top:0vh;
      padding-bottom:3vh;
      font-size: 3vh;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      margin-left: 4vw;
      width:80%;
    }

    .content-link{
      font-size: 3vh;
      color:#6100c2;
      font-family: 'Nunito', sans-serif;
    }

    .booking-form-1{
      width:100%;
      text-align: center;
      margin-left: 0;
      padding-bottom: 0vh;
    }

    .booking-form-2{
      width:90%;
      text-align: center;
      margin-left: 0;
      position: relative;
      left:5%;
    }

    .booking-1-main-booking-form-column-1{
      width:95%;
    }

    .booking-1-main-booking-form-column-2{
      width:95%;
      padding-left: 0px;
    }

    .main-form-submit-button{
      position: relative;
      left:10%;
      font-size: 3.4vw;
      width:80%;
    }


    .booking-1-error{
      margin-top:4vh;
      font-size: 4vw;
      color:#f00034;
      font-family: 'Nunito', sans-serif;
    }

    .add-ons-header{
      margin-top: 3vh;
      font-size: 3vw;
      color:#787878;
      font-family: 'Nunito', sans-serif;
    }

    .pallet-images-header{
      color:#787878;
      font-family: 'Nunito', sans-serif;
      font-size: 1rem;
      margin-left: 2vw;
      margin-top: 2vh;
    }
    .pallet-images-container{
      padding-bottom: 8vh;
      margin-left: 8vw;
      margin-top: 2vh;
    }


    .pallet-image-box{
      width:40%;
      display: inline-block;
      margin-right: 4vw;
      border-radius:10px;
      position: relative;
      margin-bottom: 1vh;
    }

    .pallet-image-filter{


    }

    .pallet-image{
      width:100%;
      display: inline-block;
      border-radius:10px;
    }

    .main-booking-1-form-submit-button{
      padding:1vh;
      padding-left:0vw;
      padding-right:0vw;
      background:linear-gradient(90deg,#ff4800,#ff8400);
      margin-top:4vh;
      font-size: 1.5rem;
      color:white;
      font-family: 'Nunito', sans-serif;
      width:80%;
      text-align: center;
      border-radius: 100px;
    }
    .main-booking-1-form-submit-button:hover{
      cursor: pointer;
      opacity:0.6;
    }



  }
