
::-webkit-scrollbar {
  width:14px;
}
::-webkit-scrollbar-thumb {
  border-radius:100px;
  background:#ABABAB;
  color:#ABABAB;
}
::-webkit-scrollbar-track {
background: #f1f1f1;
}

  @media only screen and (min-width: 768px) {

    .content-image{
      width:100%;
    }

    .technology-image{
      width:100%;
      height:28vh;
      background-image: url("../../assets/high-res/Transland_056.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .technology-content-header{
      padding-top:4vh;
      padding-bottom:4vh;
      font-size: 6vh;
      color:#484848;
      font-family: 'Oswald', sans-serif;
      margin-left: 4vw;
    }

    .technology-content-text{
      padding-top:0vh;
      padding-bottom:6vh;
      font-size: 3vh;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      margin-left: 4vw;
      width:80%;
    }

    .technology-page-container{
      position: relative;
      width:100%;
      padding-top:10vh;
      padding-bottom:10vh;
    }

    .technology-item{
      padding:2vw;
      border-radius:20px;
      margin-right:3vw;
      text-align: center;
      background:white;
      width:22%;
      display: inline-block;
      vertical-align: top;
      border: 3px solid #590094;
      min-height:20vw;
    }

    .technology-icon{
      width:6vw;
    }

    .technology-header{
      font-size: 1.4vw;
      margin-top: 4vh;
      color:#590094;
      font-family: 'Oswald', sans-serif;
    }

    .technology-read-more-button{
      position: relative;
      left:5%;
      padding:1.4vh;
      padding-left:0vw;
      padding-right:0vw;
      background:linear-gradient(90deg,#ff4800,#ff8400);
      margin-top:8vh;
      font-size: 1.4vw;
      color:white;
      font-family: 'Nunito', sans-serif;
      width:90%;
      text-align: center;
      border-radius: 100px;
    }
    .technology-read-more-button:hover{
      cursor: pointer;
      opacity:0.6;
    }

  }

  @media only screen and (max-width: 768px) {

    .content-image{
      width:100%;
    }

    .content-header{
      padding-top:4vh;
      padding-bottom:4vh;
      font-size: 5vh;
      color:#484848;
      font-family: 'Oswald', sans-serif;
      margin-left: 4vw;
    }

    .content-text{
      padding-top:0vh;
      padding-bottom:3vh;
      font-size: 3vh;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      margin-left: 4vw;
      width:80%;
    }

    .technology-page-container{
      width:100%;
      padding-top:6vh;
      padding-bottom:6vh;
      text-align: center;
    }

    .technology-item{
      padding:4vw;
      border-radius:20px;
      margin-left:2vw;
      margin-right:2vw;
      text-align: center;
      background:white;
      width:80%;
      display: inline-block;
      vertical-align: top;
      border: 3px solid #590094;
      min-height:20vw;
      margin-top:3.2vh;
    }

    .technology-icon{
      width:8vw;
    }

    .technology-header{
      font-size: 4vw;
      margin-top: 4vh;
      color:#590094;
      font-family: 'Oswald', sans-serif;
    }

    .technology-read-more-button{
      position: relative;
      left:10%;
      padding:1.4vh;
      padding-left:0vw;
      padding-right:0vw;
      background:linear-gradient(90deg,#ff4800,#ff8400);
      margin-top:8vh;
      font-size: 3.2vw;
      color:white;
      font-family: 'Nunito', sans-serif;
      width:80%;
      text-align: center;
      border-radius: 100px;
    }
    .technology-read-more-button:hover{
      cursor: pointer;
      opacity:0.6;
    }

    .technology-image{
      width:100%;
      height:14vh;
      background-image: url("../../assets/high-res/Transland_056.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

  }
