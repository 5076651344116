
.bread-trail{
  margin-left: 4vw;
  margin-top: 2vh;
}

::-webkit-scrollbar {
  width:14px;
}
::-webkit-scrollbar-thumb {
  border-radius:100px;
  background:#ABABAB;
  color:#ABABAB;
}
::-webkit-scrollbar-track {
background: #f1f1f1;
}


.cookies-notice{
  background:black;
  font-family: 'Nunito', sans-serif;
  padding:0.4vw;
  padding-top:1vh;
  padding-bottom:1vh;
  font-size: 0.9rem;
  z-index: 1000;
  color:white;
  text-align: center;
}

.accept-cookies{
  display: inline-block;
  vertical-align: middle;
  margin-left: 1vw;
  background:#32a852;
  font-family: 'Nunito', sans-serif;
  padding:0.4vw;
  color:white;
  text-align: center;
  border-radius:4px;
}



.loading-page-container{
  position: fixed;
  width:100%;
  height:100%;
  background: linear-gradient(0deg, #590094,#6100c2);
}

.loading-page-loader-container{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
}

.slogan-quote-button{
  text-align: center;
  font-size: 1.3vw;
  padding:10px;
  padding-left: 2vw;
  padding-right:2vw;
  color:#590094;
  font-family: 'Nunito', sans-serif;
  background:white;
  border-radius:100px;
  display:inline-block;
  text-shadow: 0px;
  position: relative;
  left:25%;
  transform: translate(-50%,0%);
  border:2px solid #590094;
}
.slogan-quote-button:hover{
  cursor:pointer;
}

.ukraine-aid-button{
  text-align: center;
  font-size: 1.3vw;
  padding:10px;
  padding-left: 2vw;
  padding-right:2vw;
  color:#ebe30c;
  font-family: 'Nunito', sans-serif;
  background:#1567eb;
  border-radius:100px;
  display:inline-block;
  text-shadow: 0px;
  position: relative;
  left:17%;
  margin-left: 30px;
  transform: translate(-50%,0%);
  border:2px solid #ebe30c;
}
.ukraine-aid-button:hover{
  cursor:pointer;
}


  @media only screen and (min-width: 768px) {

    .landing-content{
      width:100%;
      padding-top:5vh;
      padding-bottom:10vh;
      text-align:left;
      background-image: url("./assets/Transland_056.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
    }

    .home-booking-form{
      display: inline-block;
      position: relative;
      left:4%;
      background:white;
      border-radius:20px;
      padding:4.8vw;
      padding-top:3vh;
      padding-bottom:3vh;
      border:3px solid #590094;
      width:34%;
    }

    .home-booking-form-header{
      text-align: left;
      font-size: 1.5vw;
      color:#590094;
      font-family: 'Nunito', sans-serif;
    }

    .home-booking-form-column-1{
      display: inline-block;
      vertical-align: top;
      margin-top:1vh;
    }

    .home-booking-form-column-2{
      display: inline-block;
      vertical-align: top;
      margin-top:1vh;
      padding-left:3vw;
    }

    .home-form-submit-button{
      padding:1.4vh;
      padding-left:0vw;
      padding-right:0vw;
      background:linear-gradient(90deg,#ff4800,#ff8400);
      margin-top:4vh;
      font-size: 1.2vw;
      color:white;
      font-family: 'Nunito', sans-serif;
      width:100%;
      text-align: center;
      border-radius: 100px;
    }
    .home-form-submit-button:hover{
      cursor: pointer;
      opacity:0.6;
    }

    .home-form-arrow-icon{
      margin-left: 1vw;
      width:20px;
      height:20px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom:2px;
    }

    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
      display: block;
      width: 100%;
    }

    .slogan-text{
      position: absolute;
      top:0px;
      left:5%;
      font-size: 3.6vw;
      margin-top: 14vh;
      color:white;
      width:30%;
      font-family: 'Oswald', sans-serif;
      text-align: left;
      text-shadow: 4px 0px 8px #350064;
    }

    .purple-container{
      width:100%;
      background:linear-gradient(90deg,#590094,#6100c2);
      padding-top:4vh;
      padding-bottom:4vh;
      text-align: center;
    }

    .nav-option{
      padding:1vw;
      border-radius:16px;
      margin-left:3vw;
      margin-right:3vw;
      text-align: center;
      background:white;
      width:25%;
      display: inline-block;
      vertical-align: middle;
    }

    .nav-option-icon{
      width:3vw;
    }

    .nav-option-text{
      font-size: 1.2vw;
      margin-top: 2vh;
      color:#484848;
      font-family: 'Oswald', sans-serif;
    }

    .nav-button{
      position: relative;
      left:10%;
      padding:1.4vh;
      padding-left:0vw;
      padding-right:0vw;
      background:linear-gradient(90deg,#ff4800,#ff8400);
      margin-top:4vh;
      font-size: 1.2vw;
      color:white;
      font-family: 'Nunito', sans-serif;
      width:80%;
      text-align: center;
      border-radius: 100px;
    }
    .nav-button:hover{
      cursor: pointer;
      opacity:0.6;
    }

    .welcome-container{
      width:80%;
      padding-top:4vh;
      padding-bottom:4vh;
      text-align: center;
      position: relative;
      left:10%;
    }

    .welcome-header{
      font-size: 2.4vw;
      color:#ff4800;
      font-family: 'Oswald', sans-serif;
    }

    .welcome-text{
      font-size: 1.1vw;
      margin-top: 3vh;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      position: relative;
      left:5%;
      width:90%;
    }

    .locations-container{
      width:100%;
      text-align: center;
      padding-top:4vh;
      padding-bottom:4vh;
      flex-direction: column;
    }

    .location{
      position: relative;
      padding:1vw;
      border-radius:20px;
      margin-left:2vw;
      margin-right:2vw;
      text-align: center;
      background:white;
      width:20%;
      display: inline-block;
      vertical-align: top;
      min-height:26vw;
    }

    .location-icon{
      height:14vh;
    }

    .location-header{
      font-size: 1.5vw;
      margin-top: 3vh;
      color:#6100c2;
      font-family: 'Oswald', sans-serif;
    }

    .location-text{
      font-size: 1vw;
      margin-top: 2vh;
      color:#787878;
      font-family: 'Nunito', sans-serif;

    }

    .location-read-more-button{
      position: absolute;
      left:10%;
      bottom:0px;
      padding:1.4vh;
      padding-left:0vw;
      padding-right:0vw;
      background:linear-gradient(90deg,#590094,#6100c2);
      margin-top:4vh;
      font-size: 1.2vw;
      color:white;
      font-family: 'Nunito', sans-serif;
      width:80%;
      text-align: center;
      border-radius: 100px;
    }
    .location-read-more-button:hover{
      cursor: pointer;
      opacity:0.6;
    }

    .brexit-post{
      margin-top:4vh;
      width:100%;
      min-height:30vw;
      padding-bottom:2vh;
      background-image:url("./assets/brexit.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
    }

    .brexit-filter{
      position: absolute;
      top:0px;
      left:0px;
      width:100%;
      height:100%;
      background:linear-gradient(90deg,#590094,#6100c2);
      opacity:0.4;
    }

    .brexit-text{
      position: relative;
      left:10%;
      top:10vh;
      font-size: 2vw;
      color:white;
      font-family: 'Oswald', sans-serif;
      width:65%;
    }

    .brexit-read-more-button{
      position: relative;
      padding:1.4vh;
      padding-left:0vw;
      padding-right:0vw;
      background:linear-gradient(90deg,#ff4800,#ff8400);
      margin-top:8vh;
      font-size: 1.4vw;
      color:white;
      font-family: 'Nunito', sans-serif;
      width:40%;
      text-align: center;
      border-radius: 100px;
    }
    .brexit-read-more-button:hover{
      cursor: pointer;
      opacity:0.5;
    }

    .awards-container{
      width:100%;
      padding-top:4vh;
      padding-bottom:0vh;
      text-align: center;
    }

    .award-image{
      vertical-align: middle;
      display: inline-block;
      width:6vw;
      margin-left:3vw;
      margin-right:3vw;
    }

    .technology-container{
      width:100%;
      padding-top:6vh;
      padding-bottom:6vh;
      text-align: center;
    }

    .technology{
      padding:2vw;
      border-radius:20px;
      margin-left:2vw;
      margin-right:2vw;
      text-align: center;
      background:white;
      width:20%;
      display: inline-block;
      vertical-align: top;
      border: 2px solid #590094;
    }

    .technology-icon-main{
      width:6vw;
    }

    .technology-header-main{
      font-size: 1.5vw;
      margin-top: 2vh;
      color:#590094;
      font-family: 'Oswald', sans-serif;
    }

    .technology-read-more-button-main{
      position: relative;
      left:10%;
      padding:1.2vh;
      padding-left:0vw;
      padding-right:0vw;
      background:linear-gradient(90deg,#ff4800,#ff8400);
      margin-top:3vh;
      font-size: 1.4vw;
      color:white;
      font-family: 'Nunito', sans-serif;
      width:80%;
      text-align: center;
      border-radius: 100px;
    }
    .technology-read-more-button-main:hover{
      cursor: pointer;
      opacity:0.6;
    }


  }

  @media only screen and (max-width: 768px) {

    .slogan-quote-button{
      text-align: center;
      font-size: 4vw;
      padding:10px;
      padding-left: 4vw;
      padding-right:4vw;
      color:#590094;
      font-family: 'Nunito', sans-serif;
      background:white;
      border-radius:100px;
      display:inline-block;
      text-shadow: 0px;
      position: absolute;
      left:50%;
      transform: translate(-50%,0%);
      bottom:10px;
      border:2px solid #590094;
    }
    .slogan-quote-button:hover{
      cursor:pointer;
    }

    .ukraine-aid-button{
      text-align: center;
      font-size: 3vw;
      padding:5px;
      padding-left: 2vw;
      padding-right:2vw;
      font-family: 'Nunito', sans-serif;
      border-radius:100px;
      display:inline-block;
      text-shadow: 0px;
      position: absolute;
      margin-left: 0px;
      width:90%;
      left:50%;
      transform: translate(-50%,0%);
      top:10px;
    }
    .ukraine-aid-button:hover{
      cursor:pointer;
    }

    .landing-content{
      width:100%;
      padding-top:10vh;
      padding-bottom:10vh;
      text-align:right;
      background-image: url("./assets/Transland_056.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
    }

    .home-booking-form{
      display: inline-block;
      position: relative;
      right:calc(10% - 4.8vw);
      width:80%;
      background:white;
      border-radius:20px;
      padding:4.8vw;
      padding-top:4vh;
      padding-bottom:4vh;
      border:3px solid #590094;
      text-align: left;
    }

    .home-booking-form-header{
      text-align: left;
      font-size: 3.8vw;
      color:#590094;
      font-family: 'Nunito', sans-serif;
    }

    .home-booking-form-column-1{
      display: inline-block;
      vertical-align: top;
      margin-top:2vh;
      padding-right: 2vw;
      width:45%;
    }

    .home-booking-form-column-2{
      display: inline-block;
      vertical-align: top;
      margin-top:2vh;
      padding-left:2vw;
      width:45%;
    }

    .home-form-submit-button{
      padding:1.4vh;
      padding-left:0vw;
      padding-right:0vw;
      background:linear-gradient(90deg,#ff4800,#ff8400);
      margin-top:4vh;
      font-size: 3vw;
      color:white;
      font-family: 'Nunito', sans-serif;
      width:100%;
      text-align: center;
      border-radius: 100px;
    }
    .home-form-submit-button:hover{
      cursor: pointer;
      opacity:0.6;
    }

    .home-form-arrow-icon{
      margin-left: 1vw;
      width:20px;
      height:20px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom:2px;
    }

    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
      display: block;
      width: 100%;
    }

    .slogan-text{
      display: none;
    }

    .purple-container{
      width:100%;
      background:linear-gradient(90deg,#590094,#6100c2);
      padding-top:5vh;
      padding-bottom:5vh;
      text-align: center;
    }

    .nav-option{
      padding:1vw;
      border-radius:16px;
      margin-left:3vw;
      margin-right:3vw;
      text-align: center;
      background:white;
      width:80%;
      margin-top: 3vh;
      display: inline-block;
      vertical-align: middle;
    }

    .nav-option-icon{
      width:8vw;
    }

    .nav-option-text{
      font-size: 4vw;
      margin-top: 3vh;
      color:#484848;
      font-family: 'Oswald', sans-serif;
    }

    .nav-button{
      position: relative;
      left:10%;
      padding:1.4vh;
      padding-left:0vw;
      padding-right:0vw;
      background:linear-gradient(90deg,#ff4800,#ff8400);
      margin-top:4vh;
      font-size: 3vw;
      color:white;
      font-family: 'Nunito', sans-serif;
      width:80%;
      text-align: center;
      border-radius: 100px;
    }
    .nav-button:hover{
      cursor: pointer;
      opacity:0.6;
    }

    .welcome-container{
      width:80%;
      padding-top:8vh;
      padding-bottom:8vh;
      text-align: center;
      position: relative;
      left:10%;
    }

    .welcome-header{
      font-size: 6vw;
      color:#ff4800;
      font-family: 'Oswald', sans-serif;
    }

    .welcome-text{
      font-size: 3vw;
      margin-top: 4vh;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      position: relative;
      left:5%;
      width:90%;
    }

    .locations-container{
      width:100%;
      text-align: center;
      padding-top:6vh;
      padding-bottom:8vh;
      flex-direction: column;
    }

    .location{
      padding:2.6vw;
      border-radius:20px;
      margin-left:2vw;
      margin-right:2vw;
      margin-top:5vh;
      text-align: center;
      background:white;
      width:70%;
      display: inline-block;
      vertical-align: top;
      box-shadow: 0px 0px 8px 3px #EBE5FF;
      min-height:30vw;
    }

    .location-icon{
      width:8vw;
    }

    .location-header{
      font-size: 4vw;
      margin-top: 3vh;
      color:#6100c2;
      font-family: 'Oswald', sans-serif;
    }

    .location-text{
      font-size: 3vw;
      margin-top: 4vh;
      color:#787878;
      font-family: 'Nunito', sans-serif;
      width:90%;
    }

    .location-read-more-button{
      position: relative;
      left:10%;
      padding:1.4vh;
      padding-left:0vw;
      padding-right:0vw;
      background:linear-gradient(90deg,#590094,#6100c2);
      margin-top:4vh;
      font-size: 3vw;
      color:white;
      font-family: 'Nunito', sans-serif;
      width:80%;
      text-align: center;
      border-radius: 100px;
    }
    .location-read-more-button:hover{
      cursor: pointer;
      opacity:0.6;
    }

    .brexit-post{
      margin-top:4vh;
      width:100%;
      min-height:54vw;
      padding-bottom:2vh;
      background-image:url("./assets/brexit.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
    }

    .brexit-filter{
      position: absolute;
      top:0px;
      left:0px;
      width:100%;
      height:100%;
      background:linear-gradient(90deg,#590094,#6100c2);
      opacity:0.4;
    }

    .brexit-text{
      position: relative;
      left:10%;
      top:10vh;
      font-size: 4vw;
      color:white;
      font-family: 'Oswald', sans-serif;
      width:80%;
    }

    .brexit-read-more-button{
      position: relative;
      padding:1.4vh;
      padding-left:0vw;
      padding-right:0vw;
      background:linear-gradient(90deg,#ff4800,#ff8400);
      margin-top:8vh;
      font-size: 3.2vw;
      color:white;
      font-family: 'Nunito', sans-serif;
      width:40%;
      text-align: center;
      border-radius: 100px;
    }
    .brexit-read-more-button:hover{
      cursor: pointer;
      opacity:0.5;
    }

    .awards-container{
      width:100%;
      padding-top:4vh;
      padding-bottom:4vh;
      text-align: center;
    }

    .award-image{
      vertical-align: middle;
      display: inline-block;
      width:16vw;
      margin-left:3vw;
      margin-right:3vw;
      margin-top: 3vh;
    }

    .technology-container{
      width:100%;
      padding-top:6vh;
      padding-bottom:6vh;
      text-align: center;
    }

    .technology{
      padding:4vw;
      border-radius:20px;
      margin-left:2vw;
      margin-right:2vw;
      text-align: center;
      background:white;
      width:80%;
      display: inline-block;
      vertical-align: top;
      border: 3px solid #590094;
      min-height:20vw;
      margin-top:3.2vh;
    }

    .technology-icon-main{
      width:16vw;
    }

    .technology-header-main{
      font-size: 4vw;
      margin-top: 4vh;
      color:#590094;
      font-family: 'Oswald', sans-serif;
    }

    .technology-read-more-button-main{
      position: relative;
      left:10%;
      padding:1.4vh;
      padding-left:0vw;
      padding-right:0vw;
      background:linear-gradient(90deg,#ff4800,#ff8400);
      margin-top:8vh;
      font-size: 3.2vw;
      color:white;
      font-family: 'Nunito', sans-serif;
      width:80%;
      text-align: center;
      border-radius: 100px;
    }
    .technology-read-more-button-main:hover{
      cursor: pointer;
      opacity:0.6;
    }

  }
